import { Component } from '@angular/core';
import { BaseComponent } from 'projects/shared/src/lib/shared/base/components/base-component';
import { Role } from 'projects/shared/src/lib/shared/models/classes/users/Role';

/**
 * This component is reponsible for the display of the basic navigation that is visible
 * on all authenticated pages.
 *
 * @export
 * @class NavigationComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'intello-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: false
})
export class NavigationComponent extends BaseComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  Role = Role;
}