import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TrackByFunction } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'projects/shared/src/lib/shared/base/components/base-component';
import { HttpCacheService } from 'projects/shared/src/lib/shared/core/http/http-cache.service';
import { RoleHelper } from 'projects/shared/src/lib/shared/functions/role.helper';
import { ApplicationUser } from 'projects/shared/src/lib/shared/models/classes/users/ApplicationUser';
import { IdentityToken } from 'projects/shared/src/lib/shared/models/classes/users/IdentityToken';

import { AuthenticationService } from '../../../authentication/authentication.service';

@Component({
    selector: 'intello-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserInfoComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  user: ApplicationUser;

  RoleHelper = RoleHelper;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private httpCachingService: HttpCacheService,
  ) {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    super.addSubscription(
      this.authenticationService.credentials$.subscribe({
        next: (credentials: IdentityToken) => {
          this.user = credentials.user;
          this.cdr.detectChanges();
        }
      })
    );
    this.user = this.authenticationService.user;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   * Logs the current user out (destroys the locally storaged items) and redirects
   * the user to the login page.
   */
  logout() {
    this.authenticationService.logout().subscribe((data: any) => {
    });
  }

  trackByRole: TrackByFunction<string> = (index: number, item: string) => item;
}
