import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'projects/shared/src/lib/shared/base/components/base-component';

@Component({
    selector: 'intello-navigation-link',
    templateUrl: './navigation-link.component.html',
    styleUrls: ['./navigation-link.component.scss'],
    standalone: false
})
export class NavigationLinkComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  justifyClass: string = 'justify-center';

  // -----------------------------------------------------------------------------------------------------
  // @ OUTPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() link: string;

  @Input() fragment: string;

  @Input() icon: string;

  @Input() iconClass: string = '';

  @Input() navigationLinkClass: string = '';

  @Input() justifyPosition: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly' = 'center';
  // Can we do this directly via class="" on the host element?

  @Input() tooltip: string;

  @Input() displayName: string;

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    public router: Router,
  ) {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.justifyClass = `justify-${this.justifyPosition}`;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ INTERFACES
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ OVERRIDES
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ PROTECTED METHODS
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

}
