<div class="flex flex-row md:flex-col py-1 pt-2 px-4 md:py-0 md:pt-0 md:px-0 bg-secondary-black">

    <!-- LANGUAGES -->
    <intello-navigation-link class="w-1/6 md:w-full" link="languages" icon="language" iconClass="large-x"
        [displayName]="'Languages'">
    </intello-navigation-link>

    <!-- COURSES -->
    <intello-navigation-link class="w-1/6 md:w-full" link="courses" icon="stack_single_round" iconClass="medium"
        [displayName]="'Courses'">
    </intello-navigation-link>

    <!-- MODULES -->
    <intello-navigation-link class="w-1/6 md:w-full" link="modules" icon="circle_outline" iconClass="medium"
        [displayName]="'Modules'">
    </intello-navigation-link>

    <!-- LESSONS -->
    <intello-navigation-link class="w-1/6 md:w-full" link="lessons" icon="packages" iconClass="medium"
        [displayName]="'Lessons'">
    </intello-navigation-link>

    <!-- GROUPS -->
    <intello-navigation-link class="w-1/6 md:w-full" link="groups" icon="bookmark" iconClass="medium"
        [displayName]="'Groups'">
    </intello-navigation-link>

    <!-- FILES -->
    <intello-navigation-link class="w-1/6 md:w-full" link="files" icon="file" iconClass="medium"
        [displayName]="'Files'">
    </intello-navigation-link>

    <!-- CONTENT ELEMENTS -->
    <intello-navigation-link class="w-1/6 md:w-full" link="content-elements" iconClass="medium" icon="photo_library"
        [displayName]="'Content elements'">
    </intello-navigation-link>

    <!-- IDEAS -->
    <intello-navigation-link class="w-1/6 md:w-full" link="ideas" icon="light_bulb" iconClass="medium"
        [displayName]="'Ideas'">
    </intello-navigation-link>

    <!-- CHANNELS -->
    <intello-navigation-link class="w-1/6 md:w-full" link="channels" icon="photo_library" iconClass="medium"
        [displayName]="'Channels'">
    </intello-navigation-link>

    <!-- AFFILIATES -->
    <intello-navigation-link class="w-1/6 md:w-full" link="affiliates" icon="handshake" iconClass="medium"
        [displayName]="'Affiliates'">
    </intello-navigation-link>

    <!-- USERS -->
    <intello-navigation-link class="w-1/6 md:w-full" link="users" icon="groups" iconClass="medium"
        [displayName]="'Users'">
    </intello-navigation-link>

    <!-- ACCOUNT -->
    <intello-navigation-link class="w-1/6 md:w-full" link="account" icon="person" iconClass="medium"
        [displayName]="'My Account'">
    </intello-navigation-link>

</div>