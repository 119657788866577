import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppRoute } from 'projects/cms/src/app/shared/app.route.enum';
import { BaseComponent } from 'projects/shared/src/lib/shared/base/components/base-component';
import { HttpCacheService } from 'projects/shared/src/lib/shared/core/http/http-cache.service';
import { LanguageHelper } from 'projects/shared/src/lib/shared/functions/language.helper';
import { ApplicationUser } from 'projects/shared/src/lib/shared/models/classes/users/ApplicationUser';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { I18nService } from '../../../i18n.service';

@Component({
    selector: 'intello-content-header',
    templateUrl: './content-header.component.html',
    styleUrls: ['./content-header.component.scss'],
    standalone: false
})
export class ContentHeaderComponent extends BaseComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  user: ApplicationUser;

  Languages = LanguageHelper.GetLanguages();

  currentLanguage: any;

  basketIsActive: boolean = false;

  basketAmount: number = 0;

  searchValue: string;

  window = window;

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    public authenticationService: AuthenticationService,
    public router: Router,
    private i18nService: I18nService,
    private cdr: ChangeDetectorRef,
    private translationService: TranslateService,
    private httpCachingService: HttpCacheService
  ) {
    super();

    this.user = this.authenticationService.user;

    this.currentLanguage = LanguageHelper.GetLanguageByValueOrNull(this.i18nService.language);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  logout(): void {
    super.addSubscription(
      this.authenticationService
        .logout()
        .subscribe({
          next: () => {
            this.router.navigate([`/${AppRoute.Login}`]);
          }
        })
    );
  }

  setLanguage(language: string): void {
    this.translationService.use(language).subscribe();

    this.httpCachingService.cleanCache();
    location.reload();

    this.cdr.detectChanges();
  }
}
